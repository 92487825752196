<template>
    <b-container>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                </b-card>
            </template>
            <b-jumbotron>
                <h4
                    class="mb-4"
                >
                    <router-link
                        :to="{ name: 'classes' }"
                        class="mr-4"
                    >
                        <b-icon-arrow-left-circle scale="1.5"></b-icon-arrow-left-circle>
                    </router-link>
                    <span v-if="id">Edit Class</span>
                    <span v-else>Add Class</span>
                </h4>
                <f2-form
                    :key="loading"
                    :fields="fields"
                    :prefill="model"
                    @onButtonClick="onClick"
                ></f2-form>
            </b-jumbotron>
        </b-skeleton-wrapper>
    </b-container>
</template>
<script>
import F2Form       from "@/components/form/Form.vue"
import { mapState } from "vuex"
/*import SelectRelationship from '@/components/SelectRelationship.vue'
    components: { SelectRelationship }, */

export default {
    props: ["id"],
    components: { F2Form },
    data () {
        return {
            loading    : false,
            saving     : false,
            model      : {},
            fields     : [
                { label: "Name",          type: "input",    name: "class_name"                       },
                { label: "Teacher Names", type: "input",    name: "teacher_name",
                  options: { helpText: "Please separate each teacher name with a comma" } },
                { label: "Description",   type: "textarea", name: "description"                      },
                { label: "Location",      type: "input",    name: "class_location"                   },
                { label: "Start Time",    type: "input",    name: "start_time",    subtype: "time"   },
                { label: "End Time",      type: "input",    name: "end_time",      subtype: "time"   },
                { label: "Min Students",  type: "input",    name: "min_students",  subtype: "number" },
                { label: "Max Students",  type: "input",    name: "max_students",  subtype: "number" },
                { label: "Age Min",       type: "input",    name: "age_min",       subtype: "number" },
                { label: "Age Max",       type: "input",    name: "age_max",       subtype: "number" },
                { label: "Cost ($)",      type: "input",    name: "cost",          subtype: "number",
                  options: {
                      prepend: "$",
                      append : ".00"
                  }
                },
                { type: "button", text: "Save Class", options: { onclick: "submit" } }
            ]
        };
    },
    mounted() {
        if (this.id) this.loadRecord();
    },
    methods: {
        loadRecord() {
            this.loading = true;
            this.$api.get(`/api/admin/fetch-class`, { params: { id: this.id } }).then(({ data }) => {
                this.model   = data.record;
                this.loading = false;
            });
        },
        saveRecord(model) {
            this.saving       = true;
            alert(model);
            this.$api.post(`/api/admin/save-class`, model).then(({ data }) => {
                if (data.record)
                {
                    this.model = data.record;
                }
                this.$bvToast.toast(data.message, {
                    title           : "Notification",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
                if (data.redirect) {
                    console.log(data.redirect);
                    this.$router.push(data.redirect);
                }
                this.saving = false;
            });
        },
        onClick(field, model) {
            if (field.options) {
                if (field.options.onclick == "submit") {
                    this.saveRecord(model);
                }
            }
        }
    },
    computed: mapState(["semester"])
}
</script>